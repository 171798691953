<!-- Preloader Start -->
<div class="acr-preloader" *ngIf="isLoading">
    <div class="acr-preloader-inner">
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<!-- Preloader End -->